import { addDays, format } from 'date-fns';
import { Box, Button, HStack, Text, useToast, View, VStack } from 'native-base';

import { useAppContext } from '../../contexts/app';
import { useCalendarRange } from './hooks/useCalendarRange';
import { useDayInfo } from './hooks/useDayInfo';
import { ICalendarProps } from './types/ICalendarProps';

function CalendarRow({ children }: { children: React.ReactNode }) {
  return (
    <HStack space={1} justifyContent={"space-between"}>
      {children}
    </HStack>
  );
}

export function Calendar<Event>({
  firstWeekDate,
  lastWeekDate,
  children,
  badge,
  onDayPress,
  dayDisabled,
  dayColors,
}: ICalendarProps) {

  const { locale } = useAppContext();

  const { firstCalendarDay, lastCalendarDay, totalWeeks } = useCalendarRange({
    firstWeekDate,
    lastWeekDate,
  });

  const daysSpacing = 1;

  const toast = useToast();

  const handleDisabledDayPress = () => {
    toast.show({
      description: locale.translate("ACAO-INDISPONIVEL"),
    });
  };

  return (
    <View>
      <VStack space={daysSpacing}>
        <CalendarRow>
          {Array.from({ length: 7 }).map((_, index) => {
            const day = addDays(firstCalendarDay, index);

            const weekday = format(day, "eee");

            return (
              <View key={weekday} flex={"fit-content"}>
                <Text
                  textTransform={"uppercase"}
                  textAlign={"center"}
                  flexBasis={`${100 / 7}%`}
                >
                  {weekday.slice(0, 3)}
                </Text>
              </View>
            );
          })}
        </CalendarRow>

        {Array.from({ length: totalWeeks }).map((_, weekIndex) => {
          return (
            <CalendarRow key={weekIndex}>
              {Array.from({ length: 7 }).map((_, dayIndex) => {
                const dayInfo = useDayInfo({
                  weekIndex,
                  dayIndex,
                  referenceDate: firstCalendarDay,
                  dayColors,
                  dayDisabled,
                  badge,
                });

                return (
                  <Button
                    key={dayIndex}
                    width={"20px"}
                    flexShrink={1}
                    position={"relative"}
                    flexBasis={`${100 / 7}%`}
                    backgroundColor={`${dayInfo.colors.backgroundColor}:alpha.${dayInfo.opacity}`}
                    onPress={() => {
                      if (!onDayPress) return;

                      if (dayInfo.isDayDisabled)
                        return handleDisabledDayPress();

                      onDayPress(dayInfo.day);
                    }}
                  >
                    {children({
                      day: dayInfo.day,
                      isToday: dayInfo.isToday,
                      isAfterToday: dayInfo.isAfterToday,
                      textColor: dayInfo.colors.textColor,
                      backgroundColor: dayInfo.colors.backgroundColor,
                    })}

                    {dayInfo.badge && (
                      <Box
                        backgroundColor={dayInfo.badge.backgroundColor}
                        position={"absolute"}
                        bottom={"-7px"}
                        right={"-10px"}
                        height={"15px"}
                        width={"15px"}
                        padding={0}
                        borderRadius={"full"}
                        alignContent={"center"}
                        justifyContent={"center"}
                      >
                        <Text
                          color={dayInfo.badge.textColor}
                          textAlign={"center"}
                          fontWeight={"bold"}
                          fontSize={"10px"}
                          zIndex={99}
                        >
                          {dayInfo.badge.text}
                        </Text>
                      </Box>
                    )}
                  </Button>
                );
              })}
            </CalendarRow>
          );
        })}
      </VStack>
    </View>
  );
}
