import AsyncStorage from "@react-native-async-storage/async-storage";

export class Storage<T> {
  constructor(private readonly STORAGE_KEY: string) {}

  async store(data: T | undefined) {
    if (!data) {
      await AsyncStorage.removeItem(this.STORAGE_KEY);
      return;
    }

    return await AsyncStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
  }

  async getStored(): Promise<T | undefined> {
    const user = await AsyncStorage.getItem(this.STORAGE_KEY);

    if (!user) return undefined;

    return JSON.parse(user);
  }
}
