import { Actionsheet, VStack } from "native-base";

import { ListSelect } from "@components/ListSelect";

import { useAppContext } from "../../../../../contexts/app";
import { ISchedule } from "../../../types/ISchedule";
import { ProfessionalLabel } from "../ProfessionalLabel";
import { ScheduleTimeLabel } from "../ScheduleTimeLabel";

export function SchedulesOptions({
  items,
  onChange,
  isOpen,
  onClose,
}: {
  items: ISchedule[];
  onChange: (schedule: ISchedule | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { locale } = useAppContext();

  const handleScheduleSelect = (schedule?: ISchedule) => {
    onChange(schedule);
    onClose();
  };

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        <ListSelect
          title={locale.translate("ATENDIMENTOS-HORARIO-SELECIONE")}
          items={items}
          onItemPress={handleScheduleSelect}
        >
          {(schedule: ISchedule) => (
            <VStack space={2} alignItems={"start"}>
              <ProfessionalLabel professional={schedule.coachSpecialty.coach} />
              <ScheduleTimeLabel schedule={schedule} />
            </VStack>
          )}
        </ListSelect>
      </Actionsheet.Content>
    </Actionsheet>
  );
}
