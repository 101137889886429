import { Content, ButtonLabel, Text, SecondaryButton } from "../styles";

import { ComponentType, useEffect, useState } from "react";

import {
  BranchButton,
  BranchIcon,
  BranchText,
  WideScrollView,
  FlatList,
} from "./styles";
import { UserBranch } from "../../../contexts/app/types/UserBranch";
import { branchId } from "../../../env";
import { useAppContext } from "@contexts/app";
import { View } from "native-base";
import { VirtualizedList } from "react-native";

export function BranchSelect({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const [allowedUsers, setAllowedUsers] = useState<UserBranch[]>([]);

  const { auth, theme } = useAppContext();

  const onSuccess = (user: UserBranch) => {
    auth.login({
      email,
      password,
      clientBranchId: Number(user.clientBranch.id),
    });
  };

  useEffect(() => {
    if (!auth.usersBranches?.length) return;

    const allowedBranches = [1, Number(branchId)];

    const allowedUsers =
      Number(branchId) === 1
        ? auth.usersBranches
        : auth.usersBranches.filter((user) =>
            allowedBranches.includes(Number(user.clientBranch.id))
          );

    if (allowedUsers.length === 1) {
      onSuccess(allowedUsers[0]);
      return;
    }

    if (allowedUsers.length > 1) {
      setAllowedUsers(allowedUsers);
      return;
    }
  }, [auth.usersBranches]);

  if (!allowedUsers.length) {
    return (
      <Content>
        <Text>Não possui permissão para acessar o APP.</Text>
        <Text>Entre em contato com seu professor.</Text>
      </Content>
    );
  }

  return (
    <WideScrollView>
      <Content>
        <View style={{ marginTop: 50, marginBottom: 20 }}>
          <Text>Selecione a unidade</Text>
        </View>

        <VirtualizedList
          data={allowedUsers}
          horizontal={false}
          keyExtractor={(item: UserBranch) => item.clientBranch.id}
          getItem={(data, index) => data[index]}
          getItemCount={(data) => data.length}
          renderItem={({ item }: { item: UserBranch }) => (
            <UserOption user={item} onPress={() => onSuccess(item)} />
          )}
        ></VirtualizedList>
      </Content>
    </WideScrollView>
  );
}

function UserOption({
  user,
  onPress,
}: {
  user: UserBranch;
  onPress: (user: UserBranch) => void;
}) {
  const logoSrc = {
    uri: `https://wiki4fit.com.br/imagem.php?imagem=${user.clientBranch.client.logoPequeno}`,
  };

  return (
    <BranchButton onPress={() => onPress(user)}>
      <BranchIcon source={logoSrc} />
      <BranchText>{user.clientBranch.name}</BranchText>
    </BranchButton>
  );
}
