import { useEffect, useState } from "react";

import { ISpecialty } from "../useSpecialty";

export type IProfessional = {
  id: string;
  name: string;
  imageId: string;
};

export function useProfessional({ specialty }: { specialty?: ISpecialty }) {
  const [selectedProfessional, setSelectedProfessional] = useState<
    IProfessional | undefined
  >(undefined);

  const [professionals, setProfessionals] = useState<IProfessional[]>([]);

  const [showProfessionalOptions, setShowProfessionalOptions] = useState(false);

  useEffect(() => {
    setSelectedProfessional(undefined);
  }, [specialty, professionals]);

  return {
    selectedProfessional,
    professionals,
    setProfessionals,
    showProfessionalOptions,
    setShowProfessionalOptions,
    setSelectedProfessional: (professional?: IProfessional) => {
      setSelectedProfessional(professional);
      setShowProfessionalOptions(false);
    },
  };
}
