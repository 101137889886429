import { useRef, useEffect, useState, useCallback } from "react";
import { BackHandler } from "react-native";

import { SafeAreaView, Platform } from "react-native";
import { WebView } from "react-native-webview";

import { LoadingSplash } from "./LoadingSplash";

export function LegacyAppWebView({ uri }: { uri: string }) {
  const webViewRef = useRef<WebView>(null);
  const [canGoBack, setCantGoBack] = useState<boolean>(false);

  const [isPWALoading, setIsPWALoading] = useState<boolean>(true);

  const onAndroidBackPress = useCallback(() => {
    if (!(webViewRef.current && canGoBack)) return false;

    webViewRef.current?.goBack();
    return true;
  }, [webViewRef, canGoBack]);

  useEffect(() => {
    if (Platform.OS !== "android") return () => {};

    const listener = BackHandler.addEventListener(
      "hardwareBackPress",
      onAndroidBackPress
    );

    return () => {
      listener.remove();
    };
  }, [webViewRef, canGoBack]);

  if (Platform.OS === "web") {
    return (
      <SafeAreaView style={{ flex: 1 }}>
        <LoadingSplash showSplash={isPWALoading}>
          <iframe
            src={uri}
            style={{ flex: 1, border: 'none' }}
            onLoad={() => setIsPWALoading(false)}
          ></iframe>
        </LoadingSplash>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1, marginTop: 20 }}>
      <LoadingSplash showSplash={isPWALoading}>
        <WebView
          source={{ uri }}
          ref={webViewRef}
          onLoadEnd={() => setIsPWALoading(false)}
          onLoadProgress={({ nativeEvent }) =>
            setCantGoBack(nativeEvent.canGoBack)
          }
        />
      </LoadingSplash>
    </SafeAreaView>
  );
}
