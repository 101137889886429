import { Image, Text, TouchableOpacity, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

import { useAppContext } from "@contexts/app";
import { LanguagePage } from "@pages/Language";
import PwaPage from "@pages/Pwa";
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import { webRoot } from "@services/api";

import AppointmentSchedulesPage from "../pages/AppointmentSchedules";
import { AppointmentsSchedulesAvailablePage } from "../pages/AppointmentSchedules/Available";

type PwaPageParams = {
  page: string;
};

export type RootStackParamsList = {
  HomePage: PwaPageParams;
  TrainingsPage: PwaPageParams;
  DietsPage: PwaPageParams;
  RecordedLessonsPage: PwaPageParams;
  ChatPage: PwaPageParams;
  AgendaPage: PwaPageParams;
  AppointmentSchedulesPage: { day?: Date; scheduleId?: Number };
  AppointmentSchedulesAvailablePage: undefined;
  SubscriptionsPage: PwaPageParams;
  CheckinLessonsPage: PwaPageParams;
  EventsPage: PwaPageParams;
  ProfilePage: PwaPageParams;
  LanguagePage: PwaPageParams;
};

export const pages: {
  title: string;
  pageName: keyof RootStackParamsList;
  pwaPage?: string;
  component?: (screenProps: any) => JSX.Element;
  hidden?: boolean;
}[] = [
  {
    title: "INICIO",
    pageName: "HomePage",
    pwaPage: "HomePage",
  },
  {
    title: "TREINO",
    pageName: "TrainingsPage",
    pwaPage: "TreinosPage",
  },
  {
    title: "DIETAS",
    pageName: "DietsPage",
    pwaPage: "DietsPage",
  },
  {
    title: "AULAS GRAVADAS",
    pageName: "RecordedLessonsPage",
    pwaPage: "RecordedLessonsPage",
  },
  {
    title: "CHAT",
    pageName: "ChatPage",
    pwaPage: "MensagensPage",
  },
  {
    title: "ATENDIMENTOS",
    pageName: "AppointmentSchedulesPage",
    component: AppointmentSchedulesPage,
  },
  {
    title: "ATENDIMENTOS-AGENDAR",
    pageName: "AppointmentSchedulesAvailablePage",
    hidden: true,
    component: AppointmentsSchedulesAvailablePage,
  },
  {
    title: "AGENDA",
    pageName: "AgendaPage",
    pwaPage: "AgendaPage",
  },
  {
    title: "ASSINATURAS",
    pageName: "SubscriptionsPage",
    pwaPage: "SubscriptionsPage",
  },
  {
    title: "CHECKIN DE AULAS",
    pageName: "CheckinLessonsPage",
    pwaPage: "AulasPage",
  },
  {
    title: "EVENTOS",
    pageName: "EventsPage",
    pwaPage: "EventosPage",
  },
  {
    title: "PERFIL",
    pageName: "ProfilePage",
    pwaPage: "EscolhaSeuPerfilPage",
  },
  {
    title: "LINGUAGEM",
    pageName: "LanguagePage",
    component: LanguagePage,
  },
];

const Drawer = createDrawerNavigator<RootStackParamsList>();

function DrawerHeader() {
  const {
    auth: { user },
    theme,
  } = useAppContext();

  return (
    <View
      style={{
        height: 150,
        marginTop: 20,
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10,
        padding: 10,
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: theme.header.colors.background,
      }}
    >
      <Image
        style={{
          resizeMode: "contain",
          width: "100%",
          maxWidth: "70%",
          height: 70,
        }}
        source={theme.logo.heading.src}
      />

      <View
        style={{
          flexDirection: "row",
          marginBottom: 10,
        }}
      >
        <Image
          style={{
            width: 25,
            height: 25,
            marginRight: 5,
            borderRadius: 50,
          }}
          source={{ uri: `${webRoot.defaults.baseURL}/${user?.avatar}` }}
        />

        <Text
          style={{
            color: theme.header.colors.text,
            textAlign: "center",
            padding: 5,
          }}
        >
          {user?.name} {user?.surname}
        </Text>
      </View>
    </View>
  );
}

function LogoutButton() {
  const {
    auth,
    theme,
    locale: { translate },
  } = useAppContext();

  return (
    <TouchableOpacity
      onPress={auth.logout}
      style={{
        margin: 5,
        padding: 10,
        borderRadius: 5,
        backgroundColor: theme.sideMenu.logout.colors.background,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{
          color: theme.sideMenu.logout.colors.text,
        }}
      >
        {translate("SAIR")}
      </Text>
    </TouchableOpacity>
  );
}

function CreditsText() {
  const { theme } = useAppContext();

  return (
    <Text
      style={{
        textAlign: "center",
        padding: 5,
        marginBottom: 5,
        color: theme.sideMenu.colors.text,
      }}
    >
      Desenvolvido por WIKI4FIT
    </Text>
  );
}

function DrawerContent(props: DrawerContentComponentProps) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <DrawerContentScrollView {...props}>
        <DrawerHeader />

        <DrawerItemList {...props} />
      </DrawerContentScrollView>

      <LogoutButton />

      <CreditsText />
    </SafeAreaView>
  );
}

export function AppDrawerStack() {
  const {
    locale: { translate },
    theme,
  } = useAppContext();

  return (
    <Drawer.Navigator
      initialRouteName={"AppointmentSchedulesPage"}
      screenOptions={{
        headerStyle: { backgroundColor: theme.header.colors.background },
        headerTintColor: theme.header.colors.text,
        drawerStyle: { backgroundColor: theme.sideMenu.colors.background },
        drawerActiveTintColor: theme.sideMenu.colors.text,
        drawerInactiveTintColor: theme.sideMenu.colors.text,
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      {pages.map((page, index) => (
        <Drawer.Screen
          key={page.title}
          name={page.pageName}
          component={page.component || PwaPage}
          initialParams={{ page: page.pwaPage }}
          options={{
            title: translate(page.title),
            drawerItemStyle: {
              height: page.hidden ? 0 : undefined,
            },
          }}
        />
      ))}
    </Drawer.Navigator>
  );
}
