import { createNativeStackNavigator } from "@react-navigation/native-stack";
import LoginPage from "@pages/Login";
import { LanguagePage } from "../pages/Language";

const LoginStack = createNativeStackNavigator();

export function AppLoginStack() {
  return (
    <LoginStack.Navigator
      initialRouteName="Login"
      screenOptions={{
        headerShown: true,
      }}
    >
      <LoginStack.Screen
        name="Login"
        component={LoginPage}
        options={{
          headerShown: false,
        }}
      />
      <LoginStack.Screen
        name="Language"
        component={LanguagePage}
        options={{
          headerShown: true,
        }}
      />
    </LoginStack.Navigator>
  );
}
