import { useFormContext } from "react-hook-form";
import { webApi } from "@services/api";
import {
  ButtonLabel,
  Input,
  Loading,
  PrimaryButton,
  SecondaryButton,
} from "../styles";
import { StyleProps } from "react-native-reanimated";

export function LoadingContent({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: React.ReactNode;
}) {
  if (isLoading) return <Loading />;

  return <>{children}</>;
}

export function EmailInput({ style }: { style: StyleProps }) {
  const { register, setValue } = useFormContext();

  const onChange = (value: string) => {
    setValue("email", value, { shouldValidate: true });
  };

  return (
    <Input
      placeholder="Email"
      {...register("email")}
      onChangeText={onChange}
      autoCapitalize="none"
      keyboardType="email-address"
      autoComplete="email"
      style={style}
      autoCorrect={false}
    />
  );
}

export function RecoverButton({
  onSuccessMessage,
  onErrorMessage,
  onLoadingStateChange,
  buttonStyle,
  labelStyle,
}: {
  buttonStyle: StyleProps;
  labelStyle: StyleProps;
  onSuccessMessage: (message: string) => void;
  onErrorMessage: (message: string) => void;
  onLoadingStateChange: (isLoading: boolean) => void;
}) {
  const { handleSubmit } = useFormContext();

  const onSubmit = async (data: Record<string, string>) => {
    const { email } = data;

    onErrorMessage("");

    if (!emailValidate(email, onErrorMessage)) return;

    onLoadingStateChange(true);

    const response = await webApi.post("/public/novaSenha/", { email });

    onLoadingStateChange(false);

    if (response.status !== 200) {
      onErrorMessage("Erro ao enviar e-mail");
    }

    if (response.data.code === 0) {
      onSuccessMessage(response.data.message);
    } else {
      onErrorMessage(response.data.message);
    }
  };

  return (
    <PrimaryButton style={buttonStyle} onPress={handleSubmit(onSubmit)}>
      <ButtonLabel style={labelStyle}>Enviar</ButtonLabel>
    </PrimaryButton>
  );
}

function emailValidate(
  email: string,
  setErrorMessage: (message: string) => void
) {
  if (!email) {
    setErrorMessage("Informe seu e-mail");
    return false;
  }

  if (!email.includes("@")) {
    setErrorMessage("Informe um e-mail válido");
    return false;
  }

  return true;
}
