import { format, parse } from "date-fns";
import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { useAppContext } from "../../../contexts/app";
import { ISchedule } from "../types/ISchedule";

export function useSchedules() {
  const {
    auth: { getApi },
  } = useAppContext();

  const api = getApi();

  const { data: schedules, refetch } = useQuery<ISchedule[]>({
    initialData: [],
    queryKey: ["schedules"],
    queryFn: async () => {
      const route = "/app/specialties/schedules/self";

      return api.get(route).then((res) =>
        res.data.map(({ date, ...values }: { date: string } & ISchedule) => ({
          ...values,
          date: parse(date.slice(0, 10), "yyyy-MM-dd", new Date()),
        }))
      );
    },
  });

  const groupByDay = (
    acc: Record<string, ISchedule[]>,
    schedule: ISchedule
  ) => {
    const date = format(schedule.date, "yyyy-MM-dd");
    const groupValues = acc[date] || [];
    const newGroupValues = [...groupValues, schedule];

    return { ...acc, [date]: newGroupValues };
  };

  const schedulesByDay = useMemo(
    () => schedules.reduce(groupByDay, {}),
    [schedules]
  );

  return {
    schedules: schedulesByDay,
    refetch,
  };
}
