import { format } from "date-fns";
import { Text } from "native-base";

import { useAppContext } from "../../../../../contexts/app";
import { ButtonChange } from "../ButtonChange";

export function DaySelected({
  day,
  children,
  onPress,
}: {
  day?: Date;
  children?: React.ReactNode;
  onPress: () => void;
}) {
  const { locale } = useAppContext();

  return (
    <>
      <ButtonChange iconName="calendar" onPress={onPress}>
        <Text>
          {(day && format(day, "PPPP")) ||
            locale.translate("ATENDIMENTOS-DIA-SELECIONE")}
        </Text>
      </ButtonChange>

      {children}
    </>
  );
}
