import { Button, Heading, Text, useToast, VStack } from "native-base";
import { useEffect, useMemo } from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { RootStackParamsList } from "../../components/AppDrawerStack";
import { Page } from "../../components/Page";
import { useAppContext } from "../../contexts/app";
import { ScheduleCancelModal } from "./components/ScheduleCancelModal";
import { ScheduledList } from "./components/ScheduledList";
import { useScheduleCancel } from "./hooks/useScheduleCancel";
import { useSchedules } from "./hooks/useSchedules";

type IScreenProps = NativeStackScreenProps<
  RootStackParamsList,
  "AppointmentSchedulesPage"
>;

export default function AppointmentSchedulesPage({ navigation }: IScreenProps) {
  const {
    theme,
    locale: { translate },
  } = useAppContext();

  const toast = useToast();

  const { schedules, refetch } = useSchedules();

  const scheduleCancel = useScheduleCancel({
    onSuccess: () => {
      toast.show({
        description: translate("ATENDIMENTOS-CANCELAR-SUCESSO"),
        variant: "success",
      });
    },
    onError: () => {
      toast.show({
        description: translate("ATENDIMENTOS-CANCELAR-ERRO"),
        variant: "error",
      });
    },
    onSettled: refetch,
  });

  const hasSchedules = useMemo(
    () => Boolean(Object.keys(schedules).length),
    [schedules]
  );

  useEffect(
    () => navigation.addListener("focus", () => refetch()),
    [navigation]
  );

  return (
    <Page>
      <VStack space={4}>
        <Button
          background={theme.default.primary.background}
          onPress={() =>
            navigation.navigate("AppointmentSchedulesAvailablePage")
          }
        >
          <Text color={theme.default.primary.text}>
            {translate("ATENDIMENTOS-AGENDAR")}
          </Text>
        </Button>

        {(hasSchedules && (
          <Heading pt={5} pb={2}>
            {translate("ATENDIMENTOS-AGENDADOS")}
          </Heading>
        )) || <Text>{translate("ATENDIMENTOS-AGENDADOS-VAZIO")}</Text>}

        {Object.keys(schedules).map((day) => (
          <ScheduledList
            key={day}
            schedules={schedules[day]}
            onItemPress={scheduleCancel.setSchedule}
          />
        ))}

        <ScheduleCancelModal
          schedule={scheduleCancel.schedule}
          onConfirm={scheduleCancel.save}
          onClose={() => scheduleCancel.setSchedule(undefined)}
        />
      </VStack>
    </Page>
  );
}
