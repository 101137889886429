import { HStack, Text } from "native-base";

import { useAppContext } from "../../../../../contexts/app";
import { IProfessional } from "../../hooks/useProfessional";
import { ButtonChange } from "../ButtonChange";
import { Icon } from "../Icon";
import { ProfessionalLabel } from "../ProfessionalLabel";

function ProfessionalSelectedLabel({
  professional,
}: {
  professional: IProfessional | undefined;
}) {
  const { locale } = useAppContext();

  if (professional) return <ProfessionalLabel professional={professional} />;

  return (
    <HStack>
      <Icon iconName="user" />
      <Text>{locale.translate("ATENDIMENTOS-PROFISSIONAL-TODOS")}</Text>
    </HStack>
  );
}

export function ProfessionalSelected({
  professional,
  children,
  onPress,
}: {
  professional: IProfessional | undefined;
  children?: React.ReactNode;
  onPress: () => void;
}) {
  return (
    <>
      <ButtonChange onPress={onPress}>
        <ProfessionalSelectedLabel professional={professional} />
      </ButtonChange>

      {children}
    </>
  );
}
