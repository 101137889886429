import { isSameMonth } from "date-fns";
import { useCallback } from "react";

import { useAppContext } from "@contexts/app";

export function useDayColors({ month }: { month?: Date }) {
  const { theme } = useAppContext();

  const getIsCurrentMonth = useCallback(
    (day: Date) => {
      return Boolean(month && isSameMonth(month, day));
    },
    [month]
  );

  const getDayColors = useCallback(
    ({ day }: { day: Date }) => {
      const isCurrentMonth = getIsCurrentMonth(day);

      const colors = theme.default[isCurrentMonth ? "primary" : "secondary"];

      return {
        textColor: colors.text,
        backgroundColor: colors.background,
      };
    },
    [getIsCurrentMonth]
  );

  return { getIsCurrentMonth, getDayColors };
}
