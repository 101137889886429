import { parse } from "date-fns";
import { useToast } from "native-base";
import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { useAppContext } from "../../../../../contexts/app";
import { ISchedule } from "../../../types/ISchedule";
import { IProfessional } from "../useProfessional";
import { ISpecialty } from "../useSpecialty";
import { useAvailableProfessionals } from "./hooks/useAvailableProfessionals";
import { useSchedulesByDate } from "./hooks/useSchedulesByDate";

export function useSchedules({
  specialty,
  professional,
  setProfessionals,
  day,
  month,
}: {
  specialty?: ISpecialty;
  professional?: IProfessional;
  setProfessionals: (professionals: IProfessional[]) => void;
  day?: Date;
  month?: Date;
}) {
  const { locale, auth } = useAppContext();

  const api = auth.getApi();

  const toast = useToast();

  const [selectedSchedule, setSelectedSchedule] = useState<
    ISchedule | undefined
  >();

  const [showScheduleOptions, setShowScheduleOptions] = useState(false);

  const { getAvailableProfessionals } = useAvailableProfessionals();

  const {
    data: schedules,
    isLoading,
    isError,
  } = useQuery<ISchedule[]>({
    initialData: [],
    enabled: Boolean(specialty?.id),
    queryKey: ["schedules", specialty?.id],
    queryFn: async () => {
      const route = `/app/specialties/${specialty?.id}/schedules`;

      return api.get(route).then((res) =>
        res.data.map(({ date, ...values }: { date: string } & ISchedule) => ({
          date: parse(date.slice(0, 10), "yyyy-MM-dd", new Date()),
          ...values,
        }))
      );
    },
  });

  const { schedulesGrouped, daySchedules, monthSchedules } = useSchedulesByDate(
    {
      schedules,
      professional,
      day,
      month,
    }
  );

  useEffect(() => {
    setSelectedSchedule(undefined);
  }, [specialty, professional, day]);

  useEffect(() => {
    if (!isError) return;

    const description = locale.translate("ATENDIMENTOS-HORARIO-ERRO-LISTAR");

    toast.show({ description });
  }, [isError]);

  useEffect(() => {
    setSelectedSchedule(undefined);
  }, [schedulesGrouped]);

  useEffect(() => {
    const availableProfessionals = getAvailableProfessionals({
      specialty,
      schedules,
    });

    setProfessionals(availableProfessionals);
  }, [specialty, schedules]);

  return {
    isLoading,
    hasSchedules: useMemo(() => schedules.length > 0, [schedules]),
    monthsAvailable: schedulesGrouped,
    daySchedules,
    monthSchedules,
    selectedSchedule,
    setSelectedSchedule,
    showScheduleOptions,
    setShowScheduleOptions,
  };
}
