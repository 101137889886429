import { HStack, Text } from 'native-base';

import { ISchedule } from '../../../types/ISchedule';
import { Icon } from '../Icon';

export function ScheduleTimeLabel({
  schedule: { startTime, endTime },
}: {
  schedule: ISchedule;
}) {
  const start = startTime.slice(0, 5);
  const end = endTime.slice(0, 5);

  return (
    <HStack space={2}>
      <Icon iconName="clockcircleo" />

      <Text>
        {start} - {end}
      </Text>
    </HStack>
  );
}
