import { useEffect, useState } from 'react';

import { IProfessional } from '../useProfessional';
import { ISpecialty } from '../useSpecialty';

export function useDay({
  specialty,
  professional,
}: {
  specialty?: ISpecialty;
  professional?: IProfessional;
}) {
  const [selectedDay, setSelectedDay] = useState<Date | undefined>();

  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>();

  const [showDaysOptions, setShowDaysOptions] = useState(false);

  const [showMonthsOptions, setShowMonthsOptions] = useState(false);

  useEffect(() => {
    setSelectedDay(undefined);
  }, [specialty, professional]);  

  return {
    selectedDay,
    setSelectedDay,
    selectedMonth,
    setSelectedMonth,
    showDaysOptions,
    setShowDaysOptions,
    showMonthsOptions,
    setShowMonthsOptions,
  };
}
