import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Logo } from "@components/theme/login/Logo";
import {
  ButtonLogin,
  InputEmail,
  InputPassword,
  validateSubmit,
} from "./context";
import { Content, ErrorText, Loading } from "../styles";
import { AxiosError } from "axios";
import { useAppContext } from "@contexts/app";

import { Button, VStack, useToast } from "native-base";
import { useNavigation } from "@react-navigation/native";

export function CredentialsForm({
  onCredentialChange: onSuccess,
  onRecoverPassword,
}: {
  onCredentialChange: (params: { email: string; password: string }) => void;
  onRecoverPassword: () => void;
}) {
  const navigation = useNavigation();

  const useFormMethods = useForm();
  const {
    auth,
    locale: { translate },
  } = useAppContext();

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const onChangeLanguage = useCallback(() => {
    // Linha abaixo acusa erro porém está funcionando
    // @ts-ignore
    navigation.navigate("Language", {});
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.show({
        description: errorMessage,
      });
    }
  }, [errorMessage]);

  const getBranchError = ({ response }: AxiosError) => {
    if (response && response.status === 401) {
      return setErrorMessage("E-mail ou senha inválidos");
    }

    setErrorMessage(
      "Não foi possível realizar o login no momento, tente novamente mais tarde"
    );
  };

  const onSubmit = useCallback(() => {
    useFormMethods.handleSubmit(async (data: Record<string, string>) => {
      const { email, password } = data;

      const isValid = validateSubmit({
        email,
        password,
        onError: setErrorMessage,
      });

      if (!isValid) return;

      setIsLoading(true);

      auth
        .loadUsersBranches({ email, password })
        .then(() => onSuccess({ email, password }))
        .catch(getBranchError)
        .finally(() => setIsLoading(false));
    })();
  }, []);

  return (
    <Content>
      <FormProvider {...useFormMethods}>
        <Logo />

        {isLoading && <Loading />}

        {!isLoading && (
          <>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <InputEmail onSubmit={onSubmit} />
            <InputPassword onSubmit={onSubmit} />

            <VStack width={"100%"} space={4}>
              <ButtonLogin onSubmit={onSubmit}>
                {translate("ENTRAR")}
              </ButtonLogin>

              <ButtonLogin onSubmit={onRecoverPassword}>
                {translate("LoginPage.link")}
              </ButtonLogin>

              <Button
                colorScheme={"secondary"}
                style={{ marginTop: "40" }}
                onPress={onChangeLanguage}
              >
                {translate("LINGUAGEM")}
              </Button>
            </VStack>
          </>
        )}
      </FormProvider>
    </Content>
  );
}
