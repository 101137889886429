import { format } from "date-fns";
import { Actionsheet, Button, HStack, Text, VStack } from "native-base";

import { CoachAvatar } from "../../../../components/CoachAvatar";
import { useAppContext } from "../../../../contexts/app";
import { ISchedule } from "../../types/ISchedule";

type ScheduleCancelModalProps = {
  schedule?: ISchedule;
  onConfirm: () => void;
  onClose: () => void;
};

export function ScheduleCancelModal({
  schedule,
  onConfirm,
  onClose,
}: ScheduleCancelModalProps) {
  const {
    theme,
    locale: { translate },
  } = useAppContext();

  return (
    <Actionsheet isOpen={Boolean(schedule)} onClose={onClose}>
      {schedule && (
        <Actionsheet.Content>
          <Actionsheet.Item>
            <VStack space={2} width={"100%"}>
              <Text textTransform={"capitalize"}>
                {format(schedule.date, "PPPP")}
              </Text>

              <Text>
                {schedule.startTime.slice(0, 5)}
                {" - "}
                {schedule.endTime.slice(0, 5)}
                {" | "}
                {schedule.coachSpecialty.specialty.name}
              </Text>

              <HStack space={2} alignItems={"center"}>
                <CoachAvatar coach={schedule.coachSpecialty.coach} />

                <Text>{schedule.coachSpecialty.coach.name}</Text>
              </HStack>
            </VStack>
          </Actionsheet.Item>

          <VStack space={2} mx={6} width={"100%"}>
            <Button
              onPress={onConfirm}
              background={theme.default.primary.background}
            >
              <Text color={theme.default.primary.text}>
                Cancelar agendamento
              </Text>
            </Button>

            <Button
              onPress={onClose}
              background={theme.default.secondary.background}
            >
              <Text color={theme.default.secondary.text}>Fechar</Text>
            </Button>
          </VStack>
        </Actionsheet.Content>
      )}
    </Actionsheet>
  );
}
