import { useAppContext } from "../../../../../contexts/app";
import { ISchedule } from "../../../types/ISchedule";
import { ButtonChange } from "../ButtonChange";
import { ScheduleTimeLabel } from "../ScheduleTimeLabel";

export function ScheduleSelected({
  schedule,
  children,
  onPress,
}: {
  schedule?: ISchedule;
  children?: React.ReactNode;
  onPress: () => void;
}) {
  const { locale } = useAppContext();

  return (
    <>
      <ButtonChange onPress={onPress}>
        {(schedule && <ScheduleTimeLabel schedule={schedule} />) ||
          locale.translate("ATENDIMENTOS-HORARIO-SELECIONE")}
      </ButtonChange>

      {children}
    </>
  );
}
