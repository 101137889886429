import { Text } from "native-base";

import { ListSelect } from "@components/ListSelect";

import { useAppContext } from "../../../../../contexts/app";
import { ISpecialty } from "../../hooks/useSpecialty";

export function SpecialtiesOptions({
  items,
  isOpen,
  onChange,
  onClose,
}: {
  items: ISpecialty[];
  isOpen: boolean;
  onChange: (specialty?: ISpecialty) => void;
  onClose: () => void;
}) {
  const { locale } = useAppContext();

  const handleSelectSpecialty = (specialty?: ISpecialty) => {
    onChange(specialty);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <ListSelect items={items} onItemPress={handleSelectSpecialty}>
      {(specialty) => (
        <Text textTransform={"capitalize"}>{specialty.name}</Text>
      )}
    </ListSelect>
  );
}
