import { AxiosError } from 'axios';
import { useToast } from 'native-base';

import { RootStackParamsList } from '@components/AppDrawerStack';
import { useAppContext } from '@contexts/app';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useMutation } from '@tanstack/react-query';

type IRequestParams = {
  specialtyId: number;
  scheduleId: number;
};

type ScreenProp = NativeStackNavigationProp<
  RootStackParamsList,
  "AppointmentSchedulesPage"
>;

export function useScheduleSave() {
  const {
    auth: { getApi },
    locale: { translate },
  } = useAppContext();

  const navigation = useNavigation<ScreenProp>();

  const api = getApi();
  const toast = useToast();

  const handleRequest = async ({ specialtyId, scheduleId }: IRequestParams) => {
    const route = `/app/specialties/${specialtyId}/schedules/self/${scheduleId}`;

    return api.post(route, {}).then((response) => response.data);
  };

  const handleSuccess = () => {
    const description = translate("ATENDIMENTOS-AGENDAR-SUCESSO");
    const variant = "success";

    toast.show({ description, variant });

    setTimeout(() => {
      navigation.navigate("AppointmentSchedulesPage", {});
    }, 1000);
  };

  const handleError = (error: AxiosError) => {
    const description = [400, 404].includes(error.response?.status || 0)
      ? translate("ATENDIMENTOS-AGENDAR-HORARIO-INDISPONIVEL")
      : translate("ATENDIMENTOS-AGENDAR-ERRO");

    toast.show({ description, variant: "error" });
  };

  const { mutate, isLoading } = useMutation(handleRequest, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { mutate, isLoading };
}
