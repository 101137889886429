import { Avatar } from "native-base";

type CoachAvatarProps = {
  coach: {
    imageId: string;
  };
};

export function CoachAvatar({ coach }: CoachAvatarProps) {
  return (
    <Avatar
      size="xs"
      source={{
        uri: `https://wiki4fit.com.br/assets/images/equipes/${coach.imageId}`,
      }}
    />
  );
}
