import { endOfMonth, startOfMonth } from "date-fns";
import { useMemo } from "react";

import { ISchedule } from "../../../../../types/ISchedule";

export function useSchedulesRange({
  schedules,
}: {
  schedules: Record<string, { available: number; schedules: ISchedule[] }>;
}) {
  const days = useMemo(() => {
    return Object.keys(schedules).map((day) => {
      return schedules[day].schedules[0].date;
    });
  }, [schedules]);

  const { firstWeekDate, lastWeekDate } = useMemo(() => {
    if (!days.length) return {};

    const firstWeekDate = startOfMonth(new Date(days[0]));
    const lastWeekDate = endOfMonth(new Date(days[days.length - 1]));

    return { firstWeekDate, lastWeekDate };
  }, [schedules]);

  return { firstWeekDate, lastWeekDate };
}
