import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import { NativeBaseProvider } from "native-base";
import { useEffect } from "react";
import { Platform } from "react-native";
import OneSignal from "react-native-onesignal";

import { NavigationContainer } from "@react-navigation/native";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppDrawerStack } from "./components/AppDrawerStack";
import { AppLoginStack } from "./components/AppLoginStack";
import { AppProvider, useAppContext } from "./contexts/app";
import { Loading } from "./pages/Login/styles";

function AppContent() {
  const {
    auth: { user, isReady },
  } = useAppContext();

  if (!isReady) return <Loading />;

  if (!user?.auth) return <AppLoginStack />;

  return <AppDrawerStack />;
}

export default function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (Platform.OS === "web") return;

    if (Constants?.manifest?.extra?.oneSignalAppId) {
      OneSignal.setAppId(Constants.manifest.extra.oneSignalAppId as string);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <NativeBaseProvider>
          <NavigationContainer>
            <AppContent />
          </NavigationContainer>
          <StatusBar backgroundColor="transparent" style="auto" />
        </NativeBaseProvider>
      </AppProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
