import { format } from "date-fns";
import { Actionsheet, Text } from "native-base";
import { useMemo } from "react";

import { ListSelect } from "@components/ListSelect";
import { useAppContext } from "@contexts/app";

export function MonthsOptions({
  items,
  isOpen,
  onChange,
  onClose,
}: {
  items: Record<string, { available: number }>;
  isOpen: boolean;
  onChange: (date?: Date) => void;
  onClose: () => void;
}) {
  const { locale } = useAppContext();

  const handleChangeMonth = (month?: { date: Date }) => {
    onChange(month?.date);
    onClose();
  };

  const parsedItems = useMemo(() => {
    return Object.keys(items).map((date) => {
      const [year, month] = date.split("-");

      const monthDate = new Date(Number(year), Number(month) - 1);
      const available = items[date].available;

      return { date: monthDate, available };
    });
  }, [items]);

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        <ListSelect
          title={locale.translate("ATENDIMENTOS-MES-SELECIONE")}
          items={parsedItems}
          onItemPress={handleChangeMonth}
        >
          {({ date }) => (
            <Text textTransform={"capitalize"}>{format(date, "MMMM")}</Text>
          )}
        </ListSelect>
      </Actionsheet.Content>
    </Actionsheet>
  );
}
