import { useFormContext } from "react-hook-form";
import { useAppContext } from "@contexts/app";

import { Input, PrimaryButton, ButtonLabel } from "../styles";
import { Button } from "native-base";

export function InputEmail({ onSubmit }: { onSubmit: () => void }) {
  const { register, setValue } = useFormContext();

  const {
    theme,
    locale: { translate },
  } = useAppContext();

  const onChange = (value: string) => {
    setValue("email", value, { shouldValidate: true });
  };

  return (
    <Input
      placeholder={translate("EMAIL")}
      {...register("email")}
      onChangeText={onChange}
      onSubmitEditing={onSubmit}
      autoCapitalize="none"
      keyboardType="email-address"
      autoComplete="email"
      style={{
        backgroundColor: theme.login.input.colors.background,
        color: theme.login.input.colors.text,
      }}
      autoCorrect={false}
    />
  );
}

export function InputPassword({ onSubmit }: { onSubmit: () => void }) {
  const { register, setValue } = useFormContext();

  const {
    theme,
    locale: { translate },
  } = useAppContext();

  const onChange = (value: string) => {
    setValue("password", value, { shouldValidate: true });
  };

  return (
    <Input
      placeholder={translate("SENHA")}
      {...register("password")}
      onChangeText={onChange}
      onSubmitEditing={onSubmit}
      autoCapitalize="none"
      autoComplete="password"
      autoCorrect={false}
      style={{
        backgroundColor: theme.login.input.colors.background,
        color: theme.login.input.colors.text,
      }}
      secureTextEntry
    />
  );
}

export function ButtonLogin({
  onSubmit,
  children,
}: {
  onSubmit: () => void;
  children: string;
}) {
  const { theme } = useAppContext();

  return (
    <Button
      style={{
        backgroundColor: theme.login.button.colors.background,
      }}
      onPress={onSubmit}
    >
      <ButtonLabel
        style={{
          color: theme.login.button.colors.text,
        }}
      >
        {children}
      </ButtonLabel>
    </Button>
  );
}

export function validateSubmit({
  email,
  password,
  onError: onError,
}: {
  email: string;
  password: string;
  onError: (message: string) => void;
}) {
  onError("");

  if (!email) {
    onError("Informe o e-mail");

    return false;
  }

  if (!password) {
    onError("Informe a senha");
    return false;
  }

  return true;
}
