import { format } from "date-fns";
import { Button, Heading, HStack, Text, VStack } from "native-base";
import { useMemo } from "react";

import { AntDesign } from "@expo/vector-icons";

import { CoachAvatar } from "../../../../components/CoachAvatar";
import { useAppContext } from "../../../../contexts/app";
import { ISchedule } from "../../types/ISchedule";

type IScheduledListProps = {
  schedules: ISchedule[];
  onItemPress: (schedule?: ISchedule) => void;
};

export function ScheduledList({ schedules, onItemPress }: IScheduledListProps) {
  const { theme } = useAppContext();

  const title = useMemo(() => {
    if (!schedules.length) return "";

    return format(schedules[0].date, "PPPP");
  }, [schedules]);

  return (
    <VStack space={2}>
      <Heading size={"md"}>{title}</Heading>

      <VStack space={4}>
        {schedules.map((schedule) => (
          <HStack
            space={2}
            key={schedule.id}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <VStack>
              <HStack>
                <Text textTransform={"capitalize"}>
                  {schedule.startTime.slice(0, 5)}
                  {" - "}
                  {schedule.endTime.slice(0, 5)}
                  {" | "}
                  {schedule.coachSpecialty.specialty.name}
                </Text>
              </HStack>

              <HStack space={2} alignItems={"center"}>
                <CoachAvatar coach={schedule.coachSpecialty.coach} />

                <Text>{schedule.coachSpecialty.coach.name}</Text>
              </HStack>
            </VStack>

            <Button
              onPress={() => onItemPress(schedule)}
              background={theme.default.primary.background}
            >
              <AntDesign
                name={"close"}
                size={16}
                color={theme.default.primary.text}
              />
            </Button>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
}
