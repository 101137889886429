import { useEffect, useState } from "react";
import { ImageSourcePropType } from "react-native";

import loginBgSrc from "@assets/login-bg.png";
import logoLoginSrc from "@assets/login-logo.png";
import { useAppContext } from "@contexts/app";
import { coreApi } from "@services/api";
import { Storage } from "@services/storage";

import { branchId } from "../../../env";

type ColorsType = {
  colors: {
    background: string;
    text: string;
  };
};

type GradientType = {
  angle: number;
  colors: string[];
};

type BackgroundImageType = {
  image: {
    src: ImageSourcePropType;
    alignX: string;
    alignY: string;
  };
};

export type ThemeType = {
  default: ColorsType & {
    contentPadding: number;
    primary: ColorsType["colors"];
    secondary: ColorsType["colors"];
  };

  gradient: GradientType;

  logo: {
    login: {
      src: BackgroundImageType["image"]["src"];
      width: string | number;
      marginTop: string | number;
      marginBottom: string | number;
    };
    heading: BackgroundImageType["image"];
  };

  login: {
    background: BackgroundImageType["image"];
    button: ColorsType;
    input: ColorsType;
  };

  trainingCard: {
    background: BackgroundImageType["image"];
    title: ColorsType;
    text: ColorsType;
    button: ColorsType;
  };

  header: ColorsType;
  sideMenu: ColorsType & {
    logout: ColorsType;
  };
};

type BranchTheme = {
  id: string;
  clientBranchId: string;
  primary_color: string;
  text_above_primary_color: string;
  secondary_color: string;
  text_above_training_background: string;
  title_above_training_background: string;
  text_above_secondary_color: string;
  gradient_start_color: string;
  gradient_end_color: string;
  text_above_gradient_color: string;
  gradient_angle: number;
  logo: string;
  trainingBackgroundImage: string;
};

const DEFAULT_BRANCH_THEME: BranchTheme = {
  id: "1",
  clientBranchId: "1",
  primary_color: "#fff",
  text_above_primary_color: "#000",
  secondary_color: "#fff",
  text_above_training_background: "#000",
  title_above_training_background: "#000",
  text_above_secondary_color: "#000",
  gradient_start_color: "#fff",
  gradient_end_color: "#fff",
  text_above_gradient_color: "#000",
  gradient_angle: 0,
  logo: "",
  trainingBackgroundImage: "",
};

const getTheme = ({ branchTheme }: { branchTheme: BranchTheme }): ThemeType => {
  return {
    default: {
      contentPadding: 20,
      colors: {
        background: "#fff",
        text: "#000",
      },
      primary: {
        background: branchTheme.primary_color,
        text: branchTheme.text_above_primary_color,
      },
      secondary: {
        background: branchTheme.secondary_color,
        text: branchTheme.text_above_secondary_color,
      },
    },

    gradient: {
      angle: branchTheme.gradient_angle,
      colors: [
        branchTheme.gradient_start_color,
        branchTheme.gradient_end_color,
      ],
    },

    logo: {
      login: {
        src: logoLoginSrc,
        width: "60%",
        marginTop: 0,
        marginBottom: 10,
      },
      heading: {
        src: { uri: branchTheme.logo },
        alignX: "center",
        alignY: "center",
      },
    },

    login: {
      background: {
        src: loginBgSrc,
        alignX: "center",
        alignY: "center",
      },
      button: {
        colors: {
          background: branchTheme.primary_color,
          text: branchTheme.text_above_primary_color,
        },
      },
      input: {
        colors: {
          background: "#ffffff55",
          text: "#111111",
        },
      },
    },

    header: {
      colors: {
        background: "#fff",
        text: branchTheme.primary_color,
      },
    },

    sideMenu: {
      colors: {
        background: branchTheme.primary_color,
        text: branchTheme.text_above_primary_color,
      },
      logout: {
        colors: {
          background: branchTheme.secondary_color,
          text: branchTheme.text_above_secondary_color,
        },
      },
    },

    trainingCard: {
      background: {
        src: { uri: branchTheme.trainingBackgroundImage },
        alignX: "center",
        alignY: "center",
      },
      title: {
        colors: {
          background: branchTheme.primary_color,
          text: branchTheme.text_above_primary_color,
        },
      },
      text: {
        colors: {
          background: branchTheme.secondary_color,
          text: branchTheme.text_above_secondary_color,
        },
      },
      button: {
        colors: {
          background: branchTheme.primary_color,
          text: branchTheme.text_above_primary_color,
        },
      },
    },
  };
};

const themeStorage = new Storage<BranchTheme>("@theme");

const getThemeStyle = ({ branchId }: { branchId: number }) => {
  return coreApi.get(`/public/branch/styles/${branchId}/json`);
};

export function useAppTheme() {
  const { auth } = useAppContext();

  const [branchTheme, setBranchTheme] = useState<BranchTheme | undefined>(
    {} as BranchTheme
  );

  useEffect(() => {
    themeStorage.getStored().then(setBranchTheme);
  }, []);

  useEffect(() => {
    const userBranchId = auth?.user?.clientBranchId;

    const themeBranchId =
      Number(branchId) === 1 && userBranchId ? userBranchId : Number(branchId);

    getThemeStyle({ branchId: themeBranchId })
      .then((response) => {
        setBranchTheme(response.data);
        themeStorage.store(response.data);
      })
      .catch(() => null);
  }, [auth?.user?.clientBranchId]);

  return getTheme({ branchTheme: branchTheme || DEFAULT_BRANCH_THEME });
}
