import { format } from "date-fns";
import { useCallback, useMemo } from "react";

import { ISchedule } from "../../../../../types/ISchedule";
import { IProfessional } from "../../../useProfessional";
import { useGroupSchedules } from "./hooks/useGroupSchedules";

export function useSchedulesByDate({
  schedules,
  professional,
  day,
  month,
}: {
  schedules: ISchedule[];
  professional?: IProfessional;
  day?: Date;
  month?: Date;
}) {
  const { groupByMonthAndDay } = useGroupSchedules();

  const filterByProfessional = useCallback(
    (schedule: ISchedule) => {
      if (!professional?.id) return true;

      return schedule.coachSpecialty.coach.id === professional.id;
    },
    [professional]
  );

  const schedulesGrouped = useMemo(
    () => schedules.filter(filterByProfessional).reduce(groupByMonthAndDay, {}),
    [schedules, professional]
  );

  const monthSchedules = useMemo(() => {
    if (!month) return undefined;

    const monthString = format(month, "yyyy-MM");

    return schedulesGrouped[monthString] || {};
  }, [month, schedulesGrouped]);

  const daySchedules = useMemo(() => {
    if (!day) return undefined;

    const monthString = format(day, "yyyy-MM");
    const dayString = format(day, "yyyy-MM-dd");

    return schedulesGrouped[monthString].days[dayString] || {};
  }, [day, schedulesGrouped]);

  return {
    schedulesGrouped,
    monthSchedules,
    daySchedules,
  };
}
