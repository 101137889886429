import { AntDesign } from "@expo/vector-icons";

export type IIconName =
  | "arrowleft"
  | "arrowright"
  | "calendar"
  | "user"
  | "clockcircleo"
  | "staro";

export function Icon({
  iconName,
  onPress,
}: {
  iconName: IIconName;
  onPress?: () => void;
}) {
  return (
    <AntDesign
      onPress={() => onPress && onPress()}
      name={iconName || "arrowleft"}
      size={24}
      color="black"
    />
  );
}
