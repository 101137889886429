import localeEn from "date-fns/locale/en-US";
import localeEs from "date-fns/locale/es";
import localeBr from "date-fns/locale/pt-BR";
import setDateFnsDefaultOptions from "date-fns/setDefaultOptions";
import { getLocales } from "expo-localization";
import { I18n } from "i18n-js";
import { useCallback, useEffect, useState } from "react";

import { Storage } from "@services/storage";

const en = require("@locales/i18n/en-US.json");
const es = require("@locales/i18n/es-US.json");
const ptBR = require("@locales/i18n/pt-BR.json");

const languageStorage = new Storage<{ value: string }>("language");

export type LocaleType = {
  appLanguage: string;
  setAppLanguage: (language: string) => void;
  locales: {
    key: string;
    name: string;
    dateLocale: Locale;
  }[];
  translate: (key: string) => string;
};

const locales = [
  {
    key: "en",
    values: en,
    name: "English",
    dateLocale: localeEn,
  },
  {
    key: "es",
    values: es,
    name: "Español",
    dateLocale: localeEs,
  },
  {
    key: "ptBR",
    values: ptBR,
    name: "Português",
    dateLocale: localeBr,
  },
];

const i18n = new I18n(
  locales.reduce(
    (langs, language) => ({
      ...langs,
      [language.key]: language.values,
    }),
    {}
  )
);

const prepareLanguage = (savedLanguage: { value: string } | undefined) => {
  if (savedLanguage?.value) return savedLanguage.value;

  const deviceLanguage = getLocales()[0].languageCode;

  const hasCurrentLanguage = locales.some(
    (language) => language.key === deviceLanguage
  );

  if (hasCurrentLanguage) return deviceLanguage;

  return "ptBR";
};

export function useAppLocale(): LocaleType {
  const [appLanguage, setAppLanguage] = useState("en");

  useEffect(() => {
    languageStorage.getStored().then(prepareLanguage).then(setAppLanguage);
  }, []);

  useEffect(() => {
    const locale = locales.find((locale) => locale.key === appLanguage);

    if (!locale) return;

    setDateFnsDefaultOptions({
      locale: locale.dateLocale,
      weekStartsOn: 0,
    });
  }, [appLanguage]);

  const translate = useCallback(
    (key: string) => {
      i18n.locale = appLanguage;

      return i18n.t(key);
    },
    [appLanguage]
  );

  return {
    appLanguage,
    setAppLanguage: (language: string) => {
      languageStorage.store({ value: language });
      setAppLanguage(language);
    },
    locales,
    translate,
  };
}
