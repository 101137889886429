import { Text, TouchableOpacity, View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'

import { useAppContext } from '@contexts/app'

import { Page } from '../components/Page'

export function LanguagePage() {
  const {
    theme,
    locale: { appLanguage, setAppLanguage, locales },
  } = useAppContext();

  return (
    <Page>
      <FlatList
        data={locales}
        keyExtractor={(item) => item.key}
        renderItem={({ item }) => (
          <TouchableOpacity
            {...(appLanguage === item.key && {
              accessibilityLabel: "Selected",
              accessibilityRole: "button",
              accessibilityState: { selected: true },
            })}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
            onPress={() => setAppLanguage(item.key)}
          >
            <View
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
                borderRadius: 10,
                borderStyle: "solid",
                borderWidth: 3,
                borderColor: "#222222",
                ...(appLanguage === item.key && {
                  backgroundColor: "black",
                }),
              }}
            ></View>
            <Text>{item.name}</Text>
          </TouchableOpacity>
        )}
      />
    </Page>
  );
}
