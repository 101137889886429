import { useForm, FormProvider } from "react-hook-form";
import {
  Content,
  Text,
  ErrorText,
  SecondaryButton,
  ButtonLabel,
} from "../styles";

import { EmailInput, LoadingContent, RecoverButton } from "./context";

import { useState } from "react";
import { Logo } from "@components/theme/login/Logo";
import { useAppContext } from "@contexts/app";

export function PasswordRecover({ onCancel }: { onCancel: () => void }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { theme } = useAppContext();
  const useFormMethods = useForm();

  return (
    <Content>
      <FormProvider {...useFormMethods}>
        <Logo />

        {successMessage && (
          <>
            <Text>{successMessage}</Text>

            <SecondaryButton
              style={{
                backgroundColor: theme.login.button.colors.background,
              }}
              onPress={onCancel}
            >
              <ButtonLabel
                style={{
                  color: theme.login.button.colors.text,
                }}
              >
                Voltar
              </ButtonLabel>
            </SecondaryButton>
          </>
        )}

        {!successMessage && (
          <LoadingContent isLoading={isLoading}>
            <Text>
              Informe seu endereço de e-mail abaixo. Enviaremos um e-mail com as
              instruções para que você defina uma nova senha de acesso.
            </Text>

            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

            <EmailInput
              style={{
                background: theme.login.input.colors.background,
                color: theme.login.input.colors.text,
              }}
            />

            <RecoverButton
              buttonStyle={{
                backgroundColor: theme.login.button.colors.background,
              }}
              labelStyle={{
                color: theme.login.button.colors.text,
              }}
              onSuccessMessage={setSuccessMessage}
              onErrorMessage={setErrorMessage}
              onLoadingStateChange={setIsLoading}
            />

            <SecondaryButton
              style={{
                backgroundColor: theme.login.button.colors.background,
              }}
              onPress={onCancel}
            >
              <ButtonLabel
                style={{
                  color: theme.login.button.colors.text,
                }}
              >
                Voltar
              </ButtonLabel>
            </SecondaryButton>
          </LoadingContent>
        )}
      </FormProvider>
    </Content>
  );
}
