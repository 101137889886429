import styled from "styled-components/native";
import { loginAltColor } from "../../../env";

export const BranchButton = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff77;
  border-radius: 4px;
  margin: 0.5rem 2rem;
  padding: 10px;
`;

export const BranchText = styled.Text`
  color: ${loginAltColor};
`;

export const BranchIcon = styled.Image`
  padding: 5px;
  background-color: white;
  resize-mode: contain;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid gray;
`;

export const WideScrollView = styled.ScrollView`
  width: 100%;
`;

export const FlatList = styled.FlatList`
  width: 100%;
`;
