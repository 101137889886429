import { format } from "date-fns";

import { ISchedule } from "../../../../../../../types/ISchedule";
import { IMonthGroup } from "./types/IMonthGroup";

export function useGroupSchedules() {
  const groupByMonthAndDay = (
    acc: Record<string, IMonthGroup>,
    schedule: ISchedule
  ) => {
    const date = format(schedule.date, "yyyy-MM-dd");
    const month = format(schedule.date, "yyyy-MM");

    const monthData = {
      available: acc[month]?.available || 0,
      days: acc[month]?.days || {},
    };

    const dayData = {
      available: monthData.days[date]?.available || 0,
      schedules: monthData.days[date]?.schedules || [],
    };

    return {
      ...acc,
      [month]: {
        available: monthData.available + 1,
        days: {
          ...monthData.days,
          [date]: {
            available: dayData.available + 1,
            schedules: [...dayData.schedules, schedule],
          },
        },
      },
    };
  };

  return { groupByMonthAndDay };
}
