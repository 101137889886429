import { Button, Spinner, Text } from "native-base";
import { useCallback, useEffect } from "react";

import { RootStackParamsList } from "@components/AppDrawerStack";
import { Page } from "@components/Page";
import { useAppContext } from "@contexts/app";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { Loading } from "../../Login/styles";
import { DaySelected } from "./components/DaySelected";
import { DaysOptions } from "./components/DaysOptions";
import { MonthsOptions } from "./components/MonthsOptions";
import { ProfessionalSelected } from "./components/ProfessionalSelected";
import { ProfessionalsOptions } from "./components/ProfessionalsOptions";
import { ScheduleSelected } from "./components/ScheduleSelected";
import { SchedulesOptions } from "./components/SchedulesOptions";
import { SpecialtiesOptions } from "./components/SpecialtiesOptions";
import { SpecialtySelected } from "./components/SpecialtySelected";
import { useDay } from "./hooks/useDay";
import { useProfessional } from "./hooks/useProfessional";
import { useSchedules } from "./hooks/useSchedules";
import { useScheduleSave } from "./hooks/useScheduleSave";
import { useSpecialty } from "./hooks/useSpecialty";

type IScreenProps = NativeStackScreenProps<
  RootStackParamsList,
  "AppointmentSchedulesAvailablePage"
>;

export function AppointmentsSchedulesAvailablePage({
  navigation,
}: IScreenProps) {
  const { theme, locale } = useAppContext();

  const {
    isFetching: isLoadingSpecialties,
    isError: isErrorSpecialties,
    specialties,
    selectedSpecialty,
    showSpecialtiesOptions,
    setSelectedSpecialty,
    setShowSpecialtiesOptions,
  } = useSpecialty();

  const {
    professionals,
    setProfessionals,
    selectedProfessional,
    setSelectedProfessional,
    showProfessionalOptions,
    setShowProfessionalOptions,
  } = useProfessional({
    specialty: selectedSpecialty,
  });

  const {
    selectedDay,
    setSelectedDay,
    selectedMonth,
    setSelectedMonth,
    showDaysOptions,
    setShowDaysOptions,
    showMonthsOptions,
    setShowMonthsOptions,
  } = useDay({
    specialty: selectedSpecialty,
    professional: selectedProfessional,
  });

  const {
    isLoading: isLoadingSchedules,
    hasSchedules,
    monthsAvailable,
    monthSchedules,
    daySchedules,
    selectedSchedule,
    setSelectedSchedule,
    showScheduleOptions,
    setShowScheduleOptions,
  } = useSchedules({
    specialty: selectedSpecialty,
    professional: selectedProfessional,
    setProfessionals,
    day: selectedDay,
    month: selectedMonth,
  });

  const { mutate, isLoading: isSaving } = useScheduleSave();

  const saveSchedule = useCallback(() => {
    if (!selectedSpecialty?.id) return;

    if (!selectedSchedule?.id) return;

    mutate({
      specialtyId: Number(selectedSpecialty.id),
      scheduleId: Number(selectedSchedule.id),
    });
  }, [selectedSpecialty, selectedSchedule]);

  useEffect(() => {
    return navigation.addListener("focus", () => {
      setSelectedSpecialty(undefined);
      setSelectedProfessional(undefined);
      setSelectedDay(undefined);
      setSelectedSchedule(undefined);
    });
  }, [navigation]);

  useEffect(() => {
    if (!selectedDay) return;

    setShowScheduleOptions(true);
  }, [selectedDay]);

  return (
    <Page>
      {(isLoadingSpecialties && <Loading />) ||
        (isErrorSpecialties && (
          <Text>
            {locale.translate("ATENDIMENTOS-ESPECIALIDADE-ERRO-LISTAR")}
          </Text>
        )) ||
        (!specialties.length && (
          <Text>{locale.translate("ATENDIMENTOS-ESPECIALIDADE-VAZIO")}</Text>
        )) || (
          <SpecialtySelected
            specialty={selectedSpecialty}
            onPress={() => {
              setSelectedSpecialty(undefined);
              setShowSpecialtiesOptions(true);
            }}
          >
            {selectedSpecialty?.id && hasSchedules && (
              <ProfessionalSelected
                professional={
                  selectedProfessional || selectedSchedule?.coachSpecialty.coach
                }
                onPress={() => {
                  setSelectedProfessional(undefined);
                  setShowProfessionalOptions(true);
                }}
              >
                <DaySelected
                  day={selectedDay}
                  onPress={() => {
                    setSelectedDay(undefined);
                    setShowMonthsOptions(true);
                  }}
                >
                  {selectedDay && (
                    <ScheduleSelected
                      schedule={selectedSchedule}
                      onPress={() => {
                        setSelectedSchedule(undefined);
                        setShowScheduleOptions(true);
                      }}
                    />
                  )}
                </DaySelected>
              </ProfessionalSelected>
            )}
          </SpecialtySelected>
        )}

      {selectedSpecialty && !isLoadingSchedules && !hasSchedules && (
        <Text>
          {locale.translate("ATENDIMENTOS-ESPECIALIDADE-SEM-HORÁRIOS")}
        </Text>
      )}

      <SpecialtiesOptions
        items={specialties}
        isOpen={showSpecialtiesOptions}
        onChange={setSelectedSpecialty}
        onClose={() => setShowSpecialtiesOptions(false)}
      />

      <ProfessionalsOptions
        items={professionals}
        isOpen={showProfessionalOptions}
        onChange={setSelectedProfessional}
        onClose={() => setShowProfessionalOptions(false)}
      />

      <MonthsOptions
        items={monthsAvailable}
        isOpen={showMonthsOptions}
        onChange={(month?: Date) => {
          setSelectedMonth(month);

          if (!month) return;

          setShowDaysOptions(true);
        }}
        onClose={() => setShowMonthsOptions(false)}
      />

      <DaysOptions
        daysSchedules={monthSchedules?.days || {}}
        totalAvailable={monthSchedules?.available || 0}
        isOpen={showDaysOptions}
        onChange={setSelectedDay}
        onClose={() => setShowDaysOptions(false)}
      />

      <SchedulesOptions
        items={daySchedules?.schedules || []}
        isOpen={showScheduleOptions}
        onChange={setSelectedSchedule}
        onClose={() => setShowScheduleOptions(false)}
      />

      {selectedSpecialty?.id &&
        selectedDay &&
        selectedSchedule &&
        selectedSchedule && (
          <Button
            mt={4}
            background={theme.default.primary.background}
            disabled={isSaving}
            onPress={saveSchedule}
          >
            <Text color={theme.default.primary.text}>
              {locale.translate("SALVAR")}
            </Text>

            {isSaving && <Spinner />}
          </Button>
        )}
    </Page>
  );
}
