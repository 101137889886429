import { addDays, isSameDay, isSameMonth } from "date-fns";

import { IUseDayInfoProps } from "./types/IUseDayInfoProps";
import { IUseDayInfoValues } from "./types/IUseDayInfoValues";

export function useDayInfo({
  referenceDate,
  weekIndex,
  dayIndex,
  dayDisabled,
  dayColors,
  badge,
}: IUseDayInfoProps): IUseDayInfoValues {
  const todayDate = new Date();

  const daysToAdd = weekIndex * 7 + dayIndex;
  const dayDate = addDays(referenceDate, daysToAdd);

  const isToday = isSameDay(todayDate, dayDate);
  const isCurrentMonth = isSameMonth(todayDate, dayDate);
  const isAfterToday = dayDate > todayDate;

  const isDayDisabled =
    !dayDisabled ||
    dayDisabled({
      day: dayDate,
      isToday,
      isAfterToday,
    });

  const opacity = isDayDisabled ? 10 : 100;

  const colors = (dayColors &&
    dayColors({
      day: dayDate,
      isToday,
      isAfterToday,
    })) || { backgroundColor: "black", textColor: "white" };

  const badgeValues =
    badge &&
    badge({
      day: dayDate,
      isAfterToday,
      isToday,
    });

  return {
    day: dayDate,
    isAfterToday,
    isCurrentMonth,
    isDayDisabled,
    isToday,
    opacity,
    colors,
    badge: badgeValues,
  };
}
