import { HStack, Text } from "native-base";

import { CoachAvatar } from "../../../../../components/CoachAvatar";
import { IProfessional } from "../../hooks/useProfessional";

export function ProfessionalLabel({
  professional,
}: {
  professional: IProfessional;
}) {
  return (
    <HStack space={2}>
      <CoachAvatar coach={professional} />

      <Text>{professional.name}</Text>
    </HStack>
  );
}
