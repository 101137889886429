import { differenceInWeeks, endOfWeek, startOfWeek } from "date-fns";
import { useMemo } from "react";

import { IUseCalendarRangeProps } from "./types/IUseCalendarRangeProps";
import { IUseCalendarRangeValues } from "./types/IUseCalendarRangeValues";

export function useCalendarRange({
  firstWeekDate,
  lastWeekDate,
}: IUseCalendarRangeProps): IUseCalendarRangeValues {
  const firstCalendarDay = useMemo(
    () => startOfWeek(firstWeekDate),
    [firstWeekDate]
  );

  const lastCalendarDay = useMemo(
    () => endOfWeek(lastWeekDate),
    [lastWeekDate]
  );

  const totalWeeks = useMemo(
    () => differenceInWeeks(lastCalendarDay, firstCalendarDay) + 1,
    [firstCalendarDay, lastCalendarDay]
  );

  return {
    firstCalendarDay,
    lastCalendarDay,
    totalWeeks,
  };
}
