import { Actionsheet } from 'native-base';

import { ListSelect } from '@components/ListSelect';

import { useAppContext } from '../../../../../contexts/app';
import { IProfessional } from '../../hooks/useProfessional';
import { ProfessionalLabel } from '../ProfessionalLabel';

export function ProfessionalsOptions({
  items,
  isOpen,
  onChange,
  onClose,
}: {
  items: IProfessional[];
  isOpen: boolean;
  onChange: (professional?: IProfessional) => void;
  onClose: () => void;
}) {
  const { locale } = useAppContext();

  const handleChangeProfessional = (professional?: IProfessional) => {
    onChange(professional);
    onClose();
  };

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        <ListSelect
          title={locale.translate("ATENDIMENTOS-PROFISSIONAL-SELECIONE")}
          emptyOption={locale.translate("ATENDIMENTOS-PROFISSIONAL-TODOS")}
          items={items}
          onItemPress={handleChangeProfessional}
        >
          {(professional) => <ProfessionalLabel professional={professional} />}
        </ListSelect>
      </Actionsheet.Content>
    </Actionsheet>
  );
}
