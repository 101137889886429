import { ScrollView } from 'native-base';

import { useAppContext } from '../../contexts/app';

export function Page({ children }: { children: React.ReactNode }) {
  const { theme } = useAppContext();

  return (
    <ScrollView style={{ padding: theme.default.contentPadding }}>{children}</ScrollView>
  );
}
