import { Box, Button, FlatList, Heading, ScrollView, Text } from "native-base";

import { useAppContext } from "@contexts/app";

function SelectButton({
  children,
  isSecondary,
  onPress,
}: {
  children: React.ReactNode;
  isSecondary?: boolean;
  onPress: () => void;
}) {
  const { theme } = useAppContext();

  const colors = isSecondary ? theme.default.secondary : theme.default.primary;

  return (
    <Box py="1">
      <Button
        onPress={onPress}
        justifyContent="flex-start"
        backgroundColor={colors.background}
      >
        <Text color={colors.text} fontSize="md">
          {children}
        </Text>
      </Button>
    </Box>
  );
}

export function ListSelect<T>({
  title,
  emptyOption,
  items,
  isSecondary,
  children,
  onItemPress,
}: {
  title?: string;
  emptyOption?: string;
  items: T[];
  isSecondary?: boolean;
  children: (item: T) => React.ReactNode;
  onItemPress: (selected: T | undefined) => void;
}) {
  return (
    <ScrollView>
      {title && (
        <Heading fontSize="xl" pb="3">
          {title}
        </Heading>
      )}

      {emptyOption && (
        <SelectButton
          onPress={() => onItemPress(undefined)}
          isSecondary={isSecondary}
        >
          {emptyOption}
        </SelectButton>
      )}

      <FlatList
        data={items}
        renderItem={({ item }) => (
          <SelectButton
            onPress={() => onItemPress(item)}
            isSecondary={isSecondary}
          >
            {children(item)}
          </SelectButton>
        )}
      ></FlatList>
    </ScrollView>
  );
}
