import { format } from "date-fns";
import { useCallback } from "react";

import { useAppContext } from "@contexts/app";

import { ISchedule } from "../../../../../types/ISchedule";

export function useDayBadge({
  schedules,
}: {
  schedules: Record<string, { available: number; schedules: ISchedule[] }>;
}) {
  const { theme } = useAppContext();

  const getDayBadge = useCallback(
    ({
      day,
      isCurrentMonth,
    }: {
      day: Date;
      isCurrentMonth: boolean;
    } & unknown) => {
      const schedulesKey = format(day, "yyyy-MM-dd");

      const schedulesCount = schedules[schedulesKey]?.available;

      if (!schedulesCount || !isCurrentMonth) return undefined;

      const colorKey = isCurrentMonth ? "primary" : "secondary";

      const colors = theme.default[colorKey];

      return {
        text: schedulesCount.toString(),
        textColor: colors.background,
        backgroundColor: colors.text,
      };
    },
    [schedules]
  );

  return { getDayBadge };
}
