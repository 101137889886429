import { Dimensions } from "react-native";
import styled from "styled-components/native";
import { useAppContext } from "@contexts/app";

const dimensions = Dimensions.get("window");

const ContainerStyled = styled.ImageBackground`
  flex: 1;
  align-items: center !important;
  justify-content: center !important;
`;

export function Container({ children }: { children: React.ReactNode }) {
  const { theme } = useAppContext();

  const background = theme.login.background;

  const getAlign = (align: string) => {
    if (align === "center") return {};

    return { [align]: 0 };
  };

  return (
    <ContainerStyled
      resizeMode={(dimensions.width < 768 && "cover") || "contain"}
      source={background.src}
      style={{
        flex: 1,
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
      imageStyle={{
        resizeMode: "cover",
        position: "absolute",
        ...getAlign(background.alignY),
        ...getAlign(background.alignX),
      }}
    >
      {children}
    </ContainerStyled>
  );
}
