import { Text } from "native-base";

import { useAppContext } from "../../../../../contexts/app";
import { ISpecialty } from "../../hooks/useSpecialty";
import { ButtonChange } from "../ButtonChange";

export function SpecialtySelected({
  specialty,
  children,
  onPress,
}: {
  specialty?: ISpecialty | undefined;
  children?: React.ReactNode;
  onPress: () => void;
}) {
  const { locale } = useAppContext();

  return (
    <>
      <ButtonChange iconName="staro" onPress={onPress}>
        {(specialty?.name && (
          <Text textTransform="capitalize">{specialty.name}</Text>
        )) || (
          <Text>
            {locale.translate("ATENDIMENTOS-ESPECIALIDADE-SELECIONE")}
          </Text>
        )}
      </ButtonChange>

      {children}
    </>
  );
}
