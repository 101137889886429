import { createContext, useContext } from "react";
import { AuthType, useAuth } from "./hooks/appAuth";
import { LocaleType, useAppLocale } from "./hooks/appLocale";
import { ThemeType, useAppTheme } from "./hooks/appTheme";

type AppData = {
  auth: AuthType;
  theme: ThemeType;
  locale: LocaleType;
};

const AppContext = createContext<AppData>({} as AppData);

export const useAppContext = () => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return appContext;
};

export const AppConsumer = AppContext.Consumer;

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth();
  const theme = useAppTheme();
  const locale = useAppLocale();

  return (
    <AppContext.Provider value={{ auth, theme, locale }}>
      {children}
    </AppContext.Provider>
  );
};
