import { Box, Heading, HStack } from 'native-base';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Icon } from '../Icon';

export function ButtonChange({
  children,
  iconName,
  customIcon,
  onPress,
}: {
  children: React.ReactNode;
  iconName?: "arrowleft" | "arrowright" | "calendar" | "user" | "clockcircleo" | "staro";
  customIcon?: React.ReactNode;
  onPress: () => void;
}) {
  return (
    <Box mb={3}>
      <HStack space={2}>
        {customIcon && (
          <TouchableOpacity onPress={onPress}>{customIcon}</TouchableOpacity>
        )}

        {iconName && <Icon iconName={iconName} onPress={onPress} />}

        <Heading onPress={onPress} fontSize="xl" mt={0.5}>
          {children}
        </Heading>
      </HStack>
    </Box>
  );
}
