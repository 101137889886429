import { useToast } from 'native-base';
import { useEffect, useState } from 'react';

import { useAppContext } from '@contexts/app';
import { useQuery } from '@tanstack/react-query';

import { IProfessional } from './useProfessional';

export type ISpecialty = {
  id: string;
  name: string;
  coaches: IProfessional[];
};

export function useSpecialty() {
  const [selectedSpecialty, setSelectedSpecialty] = useState<
    ISpecialty | undefined
  >(undefined);

  const { auth, locale } = useAppContext();

  const api = auth.getApi();

  const [showSpecialtiesOptions, setShowSpecialtiesOptions] = useState(true);

  const toast = useToast();

  const {
    data: specialties,
    refetch,
    isError,
    isFetching,
  } = useQuery<ISpecialty[]>({
    initialData: [],
    enabled: !selectedSpecialty?.id,
    queryKey: ["specialty"],
    queryFn: async () => api.get("/app/specialties").then((res) => res.data),
  });

  useEffect(() => {
    if (!specialties.length || selectedSpecialty?.id) return;

    setShowSpecialtiesOptions(true);
  }, [specialties]);

  useEffect(() => {
    if (selectedSpecialty?.id) return;

    refetch();
  }, [selectedSpecialty]);

  useEffect(() => {
    if (!isError) return;

    const description = locale.translate(
      "ATENDIMENTOS-ESPECIALIDADE-ERRO-LISTAR"
    );

    toast.show({ description });
  }, [isError]);

  return {
    isFetching,
    isError,
    specialties,
    selectedSpecialty,
    showSpecialtiesOptions,
    setSelectedSpecialty,
    setShowSpecialtiesOptions: (value: boolean) => {
      setShowSpecialtiesOptions(value);
    },
  };
}
