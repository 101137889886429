import { format } from "date-fns";
import { Actionsheet, Box, Text, VStack } from "native-base";
import { useMemo } from "react";

import { Calendar } from "@components/Calendar";
import { useAppContext } from "@contexts/app";

import { Loading } from "../../../../Login/styles";
import { ISchedule } from "../../../types/ISchedule";
import { useDayBadge } from "./hooks/useDayBadge";
import { useDayColors } from "./hooks/useDayColors";
import { useSchedulesRange } from "./hooks/useSchedulesRange";

export function DaysOptions({
  isLoading,
  daysSchedules,
  totalAvailable,
  onChange,
  isOpen,
  onClose,
}: {
  daysSchedules: Record<string, { available: number; schedules: ISchedule[] }>;
  totalAvailable: number;
  onChange: (day: Date) => void;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { theme, locale } = useAppContext();

  const { getDayBadge } = useDayBadge({ schedules: daysSchedules });

  const { firstWeekDate, lastWeekDate } = useSchedulesRange({
    schedules: daysSchedules,
  });

  const { getDayColors, getIsCurrentMonth } = useDayColors({
    month: firstWeekDate,
  });

  const hasSchedules = useMemo(() => Boolean(totalAvailable), [totalAvailable]);

  const handleDaySelect = (day: Date) => {
    onChange(day);
    onClose();
  };

  if (isLoading) return <Loading />;

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content>
        {!hasSchedules && (
          <Text>
            {locale.translate("ATENDIMENTOS-ESPECIALIDADE-SEM-HORÁRIOS")}
          </Text>
        )}

        {hasSchedules && firstWeekDate && lastWeekDate && (
          <Box width={"95%"}>
            <Calendar
              firstWeekDate={firstWeekDate}
              lastWeekDate={lastWeekDate}
              dayColors={getDayColors}
              dayDisabled={({ day }) =>
                !getIsCurrentMonth(day) ||
                !daysSchedules[format(day, "yyyy-MM-dd")]?.available
              }
              badge={({ day }) =>
                getDayBadge({ day, isCurrentMonth: getIsCurrentMonth(day) })
              }
              onDayPress={handleDaySelect}
            >
              {({ day }) => {
                const isCurrentMonth = getIsCurrentMonth(day);

                const colors =
                  theme.default[isCurrentMonth ? "primary" : "secondary"];

                return (
                  <VStack alignItems={"center"} position={"relative"}>
                    <Text color={colors.text}>
                      {format(day, "MMM").toUpperCase()}
                    </Text>

                    <Text color={colors.text} position={"relative"}>
                      {format(day, "dd")}
                    </Text>
                  </VStack>
                );
              }}
            </Calendar>
          </Box>
        )}
      </Actionsheet.Content>
    </Actionsheet>
  );
}
