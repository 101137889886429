import { useEffect, useState } from "react";
import { ImageBackground, ViewStyle } from "react-native";

import splashSrc from "@assets/splash.png";

type LoadingSplashType = {
  showSplash: boolean;
  children?: React.ReactNode;
};

export function LoadingSplash({ children, showSplash }: LoadingSplashType) {
  const [splashVisible, setSplashVisible] = useState(showSplash);

  const [minSplashTimeExpired, setMinSplashTimeExpired] = useState(false);

  useEffect(() => {
    const canHideSplash = () => setMinSplashTimeExpired(true);

    setTimeout(canHideSplash, 600);
  }, []);

  useEffect(() => {
    setSplashVisible(showSplash && !minSplashTimeExpired);
  }, [showSplash, minSplashTimeExpired]);

  const splashStyle: ViewStyle = {
    position: "absolute",
    zIndex: 9999,
    top: 0,
    left: 0,
    flex: 1,
    width: "100%",
    height: "100%",
  };

  return (
    <>
      {children}

      {splashVisible && (
        <ImageBackground
          source={splashSrc}
          resizeMode="cover"
          style={splashStyle}
        />
      )}
    </>
  );
}
