import { useAppContext } from "@contexts/app";
import { Image } from "react-native";

export function Logo() {
  const { theme } = useAppContext();
  const logo = theme.logo.login;

  return (
    <Image
      resizeMode="contain"
      source={logo.src}
      style={{
        width: logo.width,
        marginTop: logo.marginTop,
        marginBottom: logo.marginBottom,
      }}
    />
  );
}
