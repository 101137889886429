import { ISchedule } from '../../../../../types/ISchedule';
import { IProfessional } from '../../../useProfessional';
import { ISpecialty } from '../../../useSpecialty';

export function useAvailableProfessionals() {
  const getAvailableProfessionals = ({
    specialty,
    schedules,
  }: {
    specialty?: ISpecialty;
    schedules: ISchedule[];
  }) => {
    if (!specialty?.id || !schedules.length) {
      return [];
    }

    const availableProfessionals = schedules.reduce(
      (acc: IProfessional[], { coachSpecialty }: ISchedule) => {
        const hasPrevious = (professional: IProfessional) =>
          professional.id === coachSpecialty.coach.id;

        if (acc.find(hasPrevious)) return acc;

        return [...acc, coachSpecialty.coach];
      },
      [] as IProfessional[]
    );

    return availableProfessionals;
  };

  return { getAvailableProfessionals };
}
