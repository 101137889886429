import { useState } from "react";
import { NativeBaseProvider } from "native-base";
import { Container } from "@components/theme/login/Container";
import { CredentialsForm } from "./CredentialsForm";
import { BranchSelect } from "./BranchSelect";
import { PasswordRecover } from "./PasswordRecover";
import { useAppContext } from "@contexts/app";

export default function LoginPage() {
  const { auth } = useAppContext();

  const [login, setLogin] = useState<{
    email: string;
    password: string;
  } | null>(null);

  const [recoverPassword, setRecoverPassword] = useState(false);

  if (recoverPassword) {
    return (
      <Container>
        <PasswordRecover onCancel={() => setRecoverPassword(false)} />
      </Container>
    );
  }

  if (!login || !auth.usersBranches?.length) {
    return (
      <Container>
        <CredentialsForm
          onCredentialChange={setLogin}
          onRecoverPassword={() => setRecoverPassword(true)}
        />
      </Container>
    );
  }

  return (
    <Container>
      <BranchSelect email={login.email} password={login.password} />
    </Container>
  );
}
