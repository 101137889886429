import { AxiosError, AxiosInstance } from "axios";
import { useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { useAppContext } from "../../../contexts/app";
import { ISchedule } from "../types/ISchedule";

type IRequestParams = {
  schedule?: ISchedule;
  api: AxiosInstance;
};

type IOnSuccess = {
  schedule?: ISchedule;
};

type IOnError = {
  schedule?: ISchedule;
  error: AxiosError;
};

type IUseScheduleCancel = {
  onSuccess: (values: IOnSuccess) => void;
  onError: ({ schedule, error }: IOnError) => void;
  onSettled?: () => void;
};

export function useScheduleCancel({
  onSettled,
  onSuccess,
  onError,
}: IUseScheduleCancel) {
  const [schedule, setSchedule] = useState<ISchedule | undefined>();

  const {
    auth: { getApi },
  } = useAppContext();

  const api = getApi();

  const handleMutate = (schedule?: ISchedule) => {
    if (!schedule) return Promise.reject();

    const route = `/app/specialties/schedules/self/${schedule.id}`;

    return api.delete(route, {}).then((response) => response.data);
  };

  const { mutate, isLoading } = useMutation(() => handleMutate(schedule), {
    onSuccess: () => {
      setSchedule(undefined);
      onSuccess({ schedule });
    },
    onError: (error: AxiosError) => onError({ schedule, error }),
    onSettled,
  });

  return {
    schedule,
    setSchedule,
    save: mutate,
    isLoading,
  };
}
